const theme = {
  screens: { md: "48rem", lg: "64rem", xl: "80rem" },
  colors: [
    "carmine",
    "nevada",
    "brown",
    "astronaut",
    "firebrick",
    "astral",
    "costa-del-sol",
    "merlot",
    "green",
    "blue",
    "eucalyptus",
  ],
}

module.exports = {
  customMedia: {
    "--media-sm-only": `screen and (max-width: calc(${theme.screens.md} - 1px))`,
    "--media-md": `screen and (min-width: ${theme.screens.md})`,
    "--media-lg": `screen and (min-width: ${theme.screens.lg})`,
    "--media-xl": `screen and (min-width: ${theme.screens.xl})`,
  },
  theme,
}
