import { animate, easeInOutQuad } from "../utils/animate"
import { qs, qsa } from "../utils/dom"
import { SwipeDetector } from "swipedetector"

const GAP = 80

export const initGallery = () => {
  const galleries = qsa(".plugin--gallery")

  galleries.forEach((gallery) => {
    setupGallery(gallery)
  })
}

const setupGallery = (gallery) => {
  const imageContainer = qs(".gallery__images", gallery)
  const images = qsa(".gallery__image", gallery)
  const controlNext = qs(".gallery__control--next", gallery)
  const controlPrevious = qs(".gallery__control--previous", gallery)

  let current = 0
  let X = (gallery.offsetWidth - images[0].offsetWidth) / 2

  const transforms = () => {
    let oldX = X
    X =
      images.reduce((a, c, i) => {
        if (i < current) return a - c.offsetWidth
        if (i == current) return a - c.offsetWidth / 2
        return a
      }, gallery.offsetWidth / 2) -
      GAP * current
    animate(
      (d) => {
        imageContainer.style = `transform: translateX(${
          (X - oldX) * d + oldX
        }px);`
      },
      300,
      easeInOutQuad,
    )
  }

  const activate = () => {
    transforms()
  }

  const next = () => {
    current = current < images.length - 1 ? current + 1 : 0
    activate()
  }

  const previous = () => {
    current = current > 0 ? current - 1 : images.length - 1
    activate()
  }

  controlNext.onclick = () => {
    next()
  }

  controlPrevious.onclick = () => {
    previous()
  }

  let events = new SwipeDetector(gallery).emitter

  events.on("left", () => {
    next()
  })

  events.on("right", () => {
    previous()
  })

  window.addEventListener("resize", transforms)

  const container = gallery
  const containerObserver = new ResizeObserver((containers) => {
    containers.forEach((_container) => {
      container.style = `height: ${_container.contentRect?.height}px;`
    })
  })

  transforms()
  containerObserver.observe(imageContainer)
}
