import { theme } from "../../../theme.config"
import { randomItem } from "../utils/math"

export const initRandomColors = () => {
  const root = document.querySelector(":root")
  if (root && root.dataset.randomColor) {
    // check storage for next color preset
    const storedColor = window.localStorage.getItem("nextColor")

    const color = storedColor || randomItem(theme.colors)
    const nextColor = randomItem(theme.colors.filter((c) => c != color))
    root.style = `--accent-color: var(--${color}); --accent-color-next: var(--${nextColor}); --font-color: var(--white);`

    // preset next color to something else
    window.localStorage.setItem("nextColor", nextColor)
  }
}
