export function animate(draw, duration, timing = linear) {
  let start = performance.now()

  requestAnimationFrame(function animate(time) {
    let timeFraction = Math.min((time - start) / duration, 1)
    let progress = timing(timeFraction)

    draw(progress)

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    }
  })
}

export const linear = (x) => x

// thx https://easings.net/
export const easeInOutSine = (x) => -(Math.cos(Math.PI * x) - 1) / 2
export const easeInOutQuad = (x) =>
  x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
