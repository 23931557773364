/* globals plausible */
import { qsa } from "../utils/dom"

export const initBiPages = () => {
  const body = document.body
  const biPages = qsa(".bi-page")
  const biPageButtons = qsa(".bi-page-button")

  const addListeners = (page) => {
    const closeButton = page.querySelector(".bi-page-control")
    closeButton.onclick = () => {
      close(page)
    }
    body.addEventListener("keydown", (e) => {
      if (e.key === "Escape") close(page)
    })
  }

  const removeListeners = (page) => {
    body.removeEventListener("keydown", (e) => {
      if (e.key === "Escape") close(page)
    })
  }

  const close = (page) => {
    if (page.classList && !page.classList.contains("closed")) {
      page.classList.add("closing")
      setTimeout(() => {
        body.classList.remove("modal-is-open")
        page.classList.remove("open")
        page.classList.add("closed")
        focus(
          biPageButtons.find(
            (button) => button.dataset.target === page.dataset.slug,
          ),
        )
      }, 300)
      removeListeners(page)
    }
  }

  const open = (slug) => {
    const page = biPages.find((page) => page.dataset.slug === slug)
    if (!page.classList.contains("open")) {
      page.classList.remove("closing")
      page.classList.remove("closed")
      body.classList.add("modal-is-open")
      page.classList.add("open")
      window.location.hash = page.dataset.slug
      focus(page)
      addListeners(page)
    }
  }

  if (biPages && biPageButtons) {
    biPageButtons.forEach((button) => {
      button.onclick = () => {
        const slug = button.dataset.target
        if (
          typeof window.plausible !== "undefined" &&
          typeof window.plausiblePath !== "undefined"
        )
          plausible("pageview", {
            u: `${window.plausiblePath}#${slug}`,
          })
        open(slug)
      }
    })
  }

  const hash = window.location.hash?.replace("#", "")
  if (hash && biPages.some((page) => page.dataset.slug === hash)) {
    open(hash)
  }
}
