/* globals plausible */
import { qsa } from "../utils/dom"
import VimeoPlayer from "@vimeo/player"

export const initVimeoVideos = () => {
  const videos = qsa("[data-oembed-data-from]")

  videos.forEach((video) => {
    const oembedData = JSON.parse(
      video.querySelector(`#${video.dataset.oembedDataFrom}`).textContent,
    )
    const options = {
      url: oembedData.url,
      dnt: true,
      width: oembedData.width,
      height: oembedData.height,
    }
    let container = video.querySelector(".embedded-video__container")
    const player = new VimeoPlayer(container, options)
    player.ready().catch((error) => {
      console.log(error)
      container.dataset.error = container.dataset.blockerError
    })
    let plausibleSent = false
    player.on("play", () => {
      if (!plausibleSent) {
        plausible("video play", {
          callback: () => {
            plausibleSent = true
          },
          props: { url: oembedData.url },
        })
      }
    })
  })
}
