import "./css/reset.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/plugins.css"
import "./css/content.css"
import "./css/animations.css"
import "./css/gallery.css"
import "./css/map.css"

import "./css/cards.css"
import "./css/cookiecontrol.css"
import "./css/persons.css"
import "./css/issues.css"
import "./css/bi-pages.css"

import "./css/admin.css"
import "./css/variables.css"
import "./css/colors.css"

import { onReady } from "./js/utils/dom"
import { initRandomColors } from "./js/components/randomColors"
import { initIssueNav, initToggleNav } from "./js/components/nav"
import { initNewsletterScripts } from "./js/components/newsletterScripts"
import { initBiPages } from "./js/components/biPages"
import { initVimeoVideos } from "./js/components/vimeo"
import { initGallery } from "./js/components/gallery"
import { initMap } from "./js/components/mapLoader"

onReady(initRandomColors)
onReady(initToggleNav)
onReady(initIssueNav)
onReady(initBiPages)
onReady(initNewsletterScripts)
onReady(initGallery)
onReady(initVimeoVideos)
onReady(initMap)
