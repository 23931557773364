import { qs, qsa } from "../utils/dom"

export function initToggleNav() {
  const body = document.body
  const closeButton = qs("[data-menu-toggle='close']", body)
  const openButton = qs("[data-menu-toggle='open']", body)

  const openMenu = (target) => {
    target.setAttribute("aria-expanded", true)
    body.classList.remove("menu-is-hidden")
    body.classList.remove("menu-is-closed")
    body.classList.add("menu-is-open")
    closeButton.focus()
  }

  const closeMenu = (target) => {
    target.setAttribute("aria-expanded", false)
    body.classList.remove("menu-is-open")
    body.classList.add("menu-is-closed")
    setTimeout(() => {
      body.classList.add("menu-is-hidden")
    }, 300)
    openButton.focus()
  }

  body.addEventListener("click", (e) => {
    const target = e.target && e.target.closest("[data-menu-toggle]")
    if (target) {
      e.preventDefault()
      if (body.classList.contains("menu-is-open")) {
        closeMenu(target)
      } else {
        openMenu(target)
      }
    }
  })
  body.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && body.classList.contains("menu-is-open")) {
      closeMenu(qs(".mobile-menu"))
    }
  })
}

export function initIssueNav() {
  const navLinks = qsa(".issue-nav--aside__link")
  navLinks.forEach((navLink) => {
    navLink.onclick = (e) => {
      e.preventDefault()
      navLink.classList.add("animate")
      setTimeout(() => {
        window.location.href = navLink.href
      }, 500)
    }
  })
}
