/* globals jQuery, plausible */
import { qsa } from "../utils/dom"

export const initNewsletterScripts = () => {
  const cleverReachForms = qsa(".cr_form")
  cleverReachForms.forEach((_f) => {
    // Script content from cleverreach snippet
    let $cr = jQuery.noConflict()
    let old_src
    $cr(document).ready(() => {
      $cr(".cr_form").submit(function () {
        $cr(this).find(".clever_form_error").removeClass("clever_form_error")
        $cr(this).find(".clever_form_note").remove()
        $cr(this)
          .find(".musthave")
          .find("input, textarea")
          .each(function () {
            if (
              jQuery.trim($cr(this).val()) == "" ||
              $cr(this).is(":checkbox") ||
              $cr(this).is(":radio")
            ) {
              if ($cr(this).is(":checkbox") || $cr(this).is(":radio")) {
                if (
                  !$cr(this)
                    .parents(".cr_ipe_item")
                    .find(":checked")
                    .is(":checked")
                ) {
                  $cr(this)
                    .parents(".cr_ipe_item")
                    .addClass("clever_form_error")
                }
              } else {
                $cr(this).addClass("clever_form_error")
              }
            }
          })
        if (
          $cr(this).attr("action").search(document.domain) > 0 &&
          $cr(".cr_form").attr("action").search("wcs") > 0
        ) {
          let cr_email = $cr(this).find("input[name=email]")
          let unsub = false
          if ($cr("input['name=cr_subunsubscribe'][value='false']").length) {
            if (
              $cr("input['name=cr_subunsubscribe'][value='false']").is(
                ":checked",
              )
            ) {
              unsub = true
            }
          }
          if (cr_email.val() && !unsub) {
            $cr.ajax({
              type: "GET",
              url:
                $cr(".cr_form").attr("action").replace("wcs", "check_email") +
                window.btoa($cr(this).find("input[name=email]").val()),
              success(data) {
                if (data) {
                  cr_email
                    .addClass("clever_form_error")
                    .before(
                      `<div class='clever_form_note cr_font'>${data}</div>`,
                    )
                  return false
                }
              },
              async: false,
            })
          }
          let cr_captcha = $cr(this).find("input[name=captcha]")
          if (cr_captcha.val()) {
            $cr.ajax({
              type: "GET",
              url:
                $cr(".cr_form").attr("action").replace("wcs", "check_captcha") +
                $cr(this).find("input[name=captcha]").val(),
              success(data) {
                if (data) {
                  cr_captcha
                    .addClass("clever_form_error")
                    .after(
                      `<div  class='clever_form_note cr_font'>${data}</div>`,
                    )
                  return false
                }
              },
              async: false,
            })
          }
        }
        if ($cr(this).find(".clever_form_error").length) {
          return false
        }
        plausible("newsletter submit")
        return true
      })
      $cr('input[class*="cr_number"]').change(function () {
        if (isNaN($cr(this).val())) {
          $cr(this).val(1)
        }
        if ($cr(this).attr("min")) {
          if ($cr(this).val() * 1 < $cr(this).attr("min") * 1) {
            $cr(this).val($cr(this).attr("min"))
          }
        }
        if ($cr(this).attr("max")) {
          if ($cr(this).val() * 1 > $cr(this).attr("max") * 1) {
            $cr(this).val($cr(this).attr("max"))
          }
        }
      })
      old_src = $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr("src")
      if ($cr("div[rel='captcha'] img:not(.captcha2_reload)").length != 0) {
        captcha_reload()
      }
    })
    function captcha_reload() {
      let timestamp = new Date().getTime()
      $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr("src", "")
      $cr("div[rel='captcha'] img:not(.captcha2_reload)").attr(
        "src",
        `${old_src}?t=${timestamp}`,
      )
      return false
    }
  })
}
